<template>
  <div>
    <v-card>
      <v-toolbar dense dark color="primary">
        <v-toolbar-title><h4 class="font-weight-light">CASH VOUCHER MONITORING</h4>
        </v-toolbar-title>
      </v-toolbar>
      <v-layout row wrap class="align-center mx-2 mt-5">
        <v-flex xs12 md12>
          <v-form ref="form" class="multi-col-validation">
            <v-layout row wrap class="mx-1 mb-5">
              <v-flex xs12 md12>
                <v-select
                  v-model="bank_id"
                  class="mx-2"
                  dense
                  outlined
                  label="Bank"
                  :items="bank_items"
                  item-value="id"
                  item-text="bank_code"
                  @change="selected_bank"
                  :rules="rules.combobox_rule"
                ></v-select>
              </v-flex>
            </v-layout>
          </v-form>
        </v-flex>
        <v-flex xs12 md12 class="mx-2">
          <h2>Total: P {{total_amount}}</h2>
          <v-data-table dense
                        :headers="headers"
                        :items="data_items"
          >
            <template v-slot:item="{ item }">
              <tr>
                <td>
                  {{ item.voucher_no }}
                </td>
                <td>
                  {{ item.cheque_date }}
                </td>
                <td>
                  {{ item.cheque_no }}
                </td>
                <td>
                  {{ formatPrice(item.amount) }}
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-flex>
      </v-layout>
    </v-card>
    <snack-bar-dialog
      :snackbar_flag="this.snackbar"
      :color="this.snackbar_color"
      :snackbar_text="this.snackbar_text"
    />
  </div>
</template>

<script>
  import {mdiAccountOutline, mdiAccountCashOutline, mdiInformationOutline} from "@mdi/js";
  import moment from "moment";
  import {mapActions, mapGetters} from "vuex";
  import snackBarDialog from '@/components/dialogs/notifications_dialog/Snackbar'
  import category from "@/store/module/data/category";

  const initialState = () => {
    return {
      data_items: [],
      bank_items: [],
      bank_id: '',
      total_amount: '0',
      headers: [
        {text: 'Voucher #', value: 'tin', sortable: false},
        {text: 'Date', value: 'last_name', sortable: false},
        {text: 'Cheque #', value: 'last_name', sortable: false},
        {text: 'Amount', value: 'last_name', sortable: false},
      ],
    }
  }
  export default {
    components: {
      snackBarDialog,
    },
    setup() {
      return {
        icons: {
          mdiAccountOutline,
          mdiAccountCashOutline,
          mdiInformationOutline,
        },
      }
    },
    data() {
      return initialState()
    },
    mounted() {
      this.initialize_data()
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
      ...mapGetters('authentication', ['employee_id', 'month_of']),
      ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text', 'company_logo']),
    },
    methods: {
      ...mapActions('system_data', ['change_snackbar']),
      ...mapActions('cash_vouchers', ['initialize_cv_monitoring']),
      initialize_data() {
        this.initialize_cv_monitoring()
          .then(response => {
            this.bank_items = response.data
          })
          .catch(error => {
            console.log(error)
          })

      },
      date_format(value) {
        return moment(value)
      },
      formatPrice(value) {
        let val = (value / 1).toFixed(2).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      },
      selected_bank(value) {
        var index = this.bank_items.map(function (x) {
          return x.id;
        }).indexOf(value)
        if (index != -1) {
          this.data_items = this.bank_items[index].data
          var tto = 0;
          this.data_items.forEach(function (item) {
            tto += parseFloat(item.amount)
          });
          this.total_amount = this.formatPrice(tto)
        }
      },
    },
  }
</script>
